<script setup lang="ts">
import type { DownLoadPDF } from '~/composables/usePdfRaptor'

const props = defineProps<{
  downloadInformations: DownLoadPDF
}>()
const formRaptor = ref<HTMLFormElement | null>(null)

watch(
  () => props.downloadInformations.documentUrl,
  (newVal) => {
    if (newVal) {
      nextTick(() => {
        formRaptor.value?.submit()
      })
    }
  },
)
</script>

<template>
  <form ref="formRaptor" action="https://docraptor.com/docs" method="post">
    <input
      :value="downloadInformations.apiKey"
      class="hidden"
      name="user_credentials"
    />
    <input
      :value="downloadInformations.pipeline"
      class="hidden"
      name="doc[pipeline]"
    />
    <input :value="downloadInformations.name" class="hidden" name="doc[name]" />
    <input :value="downloadInformations.test" class="hidden" name="doc[test]" />
    <input
      :value="downloadInformations.documentUrl"
      class="hidden"
      name="doc[document_url]"
    />
    <input
      :value="downloadInformations.documentType"
      class="hidden"
      name="doc[document_type]"
    />
  </form>
</template>
